import {
    Lookup,
    LookupClassification,
    LookupDiscount,
    LookupDispensingItem,
    LookupItemTypes,
    LookupPricingChangePolicies,
    LookupRoundingPrecisions,
    LookupSpecialActionAvailabilities,
    LookupTax,
    LookupType,
    LookupUnitsOfMeasure,
    LookupSupplier,
    LookupAnalysisGroup,
} from '../../core/models/datasource/lookups';
import { isMatch } from 'lodash';

type LookupsData = {
    [key in LookupType]: Lookup[];
};

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export class LookupsMap {
    private lookupsData = this.populateAvailableLookupTypes();

    constructor(lookups: Lookup[]) {
        this.sortLookups(lookups);
    }

    static getLookupResults<T extends object>(lookups: Lookup<T>[], siteId?: string, query?: Nullable<Partial<T>>): T[] {
        const items = (siteId && lookups.find((lookup) => lookup.provider.siteId === siteId)?.results) || lookups[0]?.results;
        const result = query ? items?.filter((item) => isMatch(item, query)) : items;

        return result || [];
    }

    get classifications(): Lookup<LookupClassification>[] {
        return this.lookupsData[LookupType.CLASSIFICATIONS] as Lookup<LookupClassification>[];
    }

    get discounts(): Lookup<LookupDiscount>[] {
        return this.lookupsData[LookupType.DISCOUNTS] as Lookup<LookupDiscount>[];
    }

    get dispensingItems(): Lookup<LookupDispensingItem>[] {
        return this.lookupsData[LookupType.DISPENSING_ITEMS] as Lookup<LookupDispensingItem>[];
    }

    get invoiceItemTypes(): Lookup<LookupItemTypes>[] {
        return this.lookupsData[LookupType.ITEM_TYPES] as Lookup<LookupItemTypes>[];
    }

    get pricingChangePolicy(): Lookup<LookupPricingChangePolicies>[] {
        return this.lookupsData[LookupType.PRICING_CHANGE_POLICY] as Lookup<LookupPricingChangePolicies>[];
    }

    get roundingPrecisions(): Lookup<LookupRoundingPrecisions>[] {
        return this.lookupsData[LookupType.ROUNDING] as Lookup<LookupRoundingPrecisions>[];
    }

    get specialActionAvailabilities(): Lookup<LookupSpecialActionAvailabilities>[] {
        return this.lookupsData[LookupType.SPECIAL_ACTION_TYPES] as Lookup<LookupSpecialActionAvailabilities>[];
    }

    get taxes(): Lookup<LookupTax>[] {
        return this.lookupsData[LookupType.TAXES] as Lookup<LookupTax>[];
    }

    get unitsOfMeasure(): Lookup<LookupUnitsOfMeasure>[] {
        return this.lookupsData[LookupType.UNITS] as Lookup<LookupUnitsOfMeasure>[];
    }

    get suppliers(): Lookup<LookupSupplier>[] {
        return this.lookupsData[LookupType.SUPPLIERS] as Lookup<LookupSupplier>[];
    }

    get analysisGroups(): Lookup<LookupAnalysisGroup>[] {
        return this.lookupsData[LookupType.ANALYSIS_GROUPS] as Lookup<LookupAnalysisGroup>[];
    }

    private populateAvailableLookupTypes(): LookupsData {
        return Object.values(LookupType).reduce((acc, cur) => {
            acc[cur] = [];
            return acc;
        }, {} as LookupsData);
    }

    private sortLookups(lookups: Lookup[]): void {
        lookups.forEach((lookup) => {
            if (!this.lookupsData[lookup.lookupType]) {
                return;
            }
            this.lookupsData[lookup.lookupType].push(lookup);
        });
    }
}
