import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { BulkUpdatesBody, GetItemQuery, InvoiceItem } from '../core/models';
import { InvoiceItemService } from '../services/InvoiceItemService';
import { NotificationContent, Notifications } from '../pages/Notifications';
import { RouterStore } from './RouterStore';
import { InvoiceItemEditType } from '../pages/controlCenter/items/InvoiceItemEditType.enum';
import { RouterPaths } from '../router/RouterPaths';
import { castArray, isEqual, isNil } from 'lodash';
import AppStore from './AppStore';
import { BulkStatusEditInfo } from '../pages/controlCenter/items/formType/BulkStatusEditFormType';
import { PriceDeltaUpdateEntity } from '../pages/controlCenter/items/formType/BulkPriceEditFormType';
import { PatchDeltaUpdate } from '../core/models/datasource/invoiceItems/parts/patchDeltaUpdate';
import { BulkUpdateFlyoverType } from '../pages/controlCenter/items/ItemsPageStore';
import i18n from '../i18n';
import { ToastWithSubHeader } from '../components/toasts/ToastWithSubheader';
import { PatchUpdateModel } from '../pages/controlCenter/items/formType/bulkPatchEditFormType';

export interface BulkUpdateParams {
    includedItems?: string[];
    excludedItems?: string[];
    update: PriceDeltaUpdateEntity | BulkStatusEditInfo | PatchUpdateModel;
    type: BulkUpdateFlyoverType;
    itemsSearchQuery?: GetItemQuery;
    processedItemsCount: number;
    processedGroupedItemsCount: number;
}

export interface ItemEditQuery {
    id?: string;
    description?: string;
    isSub?: boolean;
    practice?: string;
    // TODO: can be turned on when classification grouping will be available
    // classification: string;
    // add here query parameters to extend edit items feature
}

export interface OpenItemEditParams {
    query: ItemEditQuery;
    newWindow?: boolean;
}

export interface SelectionModel {
    items?: Map<string, InvoiceItem>;
    excluded?: Map<string, InvoiceItem>;
    all?: boolean;
    total?: number;
    groups?: number;
}

export interface PaginationModel {
    scrollId?: string;
    total?: number;
    groups?: number;
    page?: number;
}

export interface UpdateInvoiceItems {
    items: Partial<InvoiceItem>[];
    runAt?: Date;
    message: NotificationContent;
    name?: string;
    updateExistingItems?: boolean;
}

export interface ColumnState {
    name: string;
    width?: number;
}

export interface GridState {
    widthModel?: Record<number | string, number>;
    pinned?: {
        left?: string[];
        right?: string[];
    };
    groupingModel?: string[];
    visibilityModel?: Record<number | string, boolean | undefined>;
    sortModel?: {
        field: string;
        sort: 'asc' | 'desc' | null | undefined;
    }[];
    orderModel?: Record<number | string, number>;
}

export interface InvoiceItemsState {
    grid?: GridState;
}

@Service()
export class InvoiceItemStore {
    private _isLoadingItems = false;
    private _isLoadingItemsStats = false;
    private _items?: InvoiceItem[];
    private _selectionModel?: SelectionModel;
    private _pagingModel?: PaginationModel;
    private _timeout?: unknown;

    get isLoadingItems(): boolean {
        return this._isLoadingItems;
    }

    get isLoadingItemsStats(): boolean {
        return this._isLoadingItemsStats;
    }

    get items(): InvoiceItem[] | undefined {
        return this._items && toJS(this._items);
    }

    get selectionModel(): SelectionModel | undefined {
        return toJS(this._selectionModel);
    }

    get pagingModel(): PaginationModel | undefined {
        return toJS(this._pagingModel);
    }

    get selectedItems(): InvoiceItem[] | undefined {
        if (this._selectionModel?.all) {
            return toJS(this._items);
        }

        return this._selectionModel?.items && [...toJS(this._selectionModel?.items)?.values()];
    }

    get columnsState(): GridState | undefined {
        return toJS(this.appStore.userState?.invoiceItems?.grid);
    }

    constructor(private invoiceItemService: InvoiceItemService, private router: RouterStore, private appStore: AppStore) {
        makeAutoObservable(this);
    }

    updateColumnsState = (state: GridState) => {
        const currentState = toJS(this.appStore.userState?.invoiceItems);
        if (isEqual(currentState?.grid, state)) {
            return;
        }

        const invoiceItemsState = { invoiceItems: { ...currentState, grid: state } };
        this.appStore.updateUserState(invoiceItemsState);
    };

    fetchItems = async (query: GetItemQuery = {}): Promise<void> => {
        if (this._isLoadingItems) {
            return;
        }

        try {
            this._isLoadingItems = true;

            const { data, hits, scrollId } = await this.invoiceItemService.getItems(query);

            if (query.scrollId && query.scroll && this._items) {
                this._items = [...this._items, ...data];
            } else {
                this._items = [];
                this._items = data;
                this.updateSelectionModel(undefined);
            }

            this._pagingModel = {
                total: hits?.total,
                groups: hits?.groups,
                page: hits?.page,
                scrollId: scrollId,
            };
        } finally {
            this._isLoadingItems = false;
        }
    };

    async fetchItemsForAutoComplete(query: GetItemQuery = {}): Promise<InvoiceItem[]> {
        query.ids = undefined;
        query.description = undefined;
        query.searchType = query.searchType || undefined;

        const { data } = await this.invoiceItemService.getItems({ ...query, skipChildren: true });
        return data;
    }

    updateSelectionModel = (params?: SelectionModel): void => {
        if (this._selectionModel?.all && params?.items && params.all === undefined) {
            const excluded = new Map<string, InvoiceItem>();

            this._items?.forEach((item) => {
                const { pimsGeneratedId, hospitalDescription, provider } = item;
                const key = `${pimsGeneratedId}-${hospitalDescription}-${provider.siteId}`;

                if (!params.items?.has(key)) {
                    excluded.set(key, item);
                }
            });

            this._selectionModel = {
                all: true,
                items: this._selectionModel.items,
                excluded,
            };
        } else {
            this._selectionModel = {
                all: params?.all,
                items: params?.items,
                excluded: undefined,
            };
        }
    };

    saveItems = async (props: UpdateInvoiceItems): Promise<void> => {
        const { items, runAt = new Date(), message, name = 'Add to Additional Practices', updateExistingItems } = props;

        if (!items.length) {
            return;
        }

        try {
            await this.invoiceItemService.scheduleSave(items, { isUpdatingExistingItem: updateExistingItems, name, runAt });
            Notifications.success(message);
        } catch (error) {
            Notifications.fromException(error);
            throw error;
        }
    };

    openItemEditForm = ({ query, newWindow }: OpenItemEditParams) => {
        const editType = InvoiceItemEditType.Form;
        const searchItems = Object.keys(query).reduce((agg, key) => {
            return !isNil(query[key]) ? [...agg, `${key}=${encodeURIComponent(query[key])}`] : agg;
        }, [] as string[]);

        const path = `${RouterPaths.ControlCenterPages.Items}/${editType}?${searchItems.join('&')}`;

        if (newWindow) {
            const tab = window.open(path, '_blank');
            tab?.focus();
            return;
        }

        this.router.push(path);
    };

    createBulkUpdate = async ({
        includedItems,
        excludedItems,
        update,
        type,
        itemsSearchQuery,
        processedItemsCount,
        processedGroupedItemsCount,
    }: BulkUpdateParams): Promise<void> => {
        const { name, updateAt, ...data } = update;
        const isScheduledUpdate = Boolean(updateAt);
        const patchUpdateTypes = [
            BulkUpdateFlyoverType.SupplierName,
            BulkUpdateFlyoverType.SupplierCode,
            BulkUpdateFlyoverType.AnalysisGroup1,
            BulkUpdateFlyoverType.AnalysisGroup2,
            BulkUpdateFlyoverType.AnalysisGroup3,
            BulkUpdateFlyoverType.Classification,
            BulkUpdateFlyoverType.Description,
        ];
        const bulkUpdatesBody: BulkUpdatesBody = {
            searchQuery: itemsSearchQuery,
            includedItems,
            excludedItems,
            name: name || 'Bulk Update',
            runAt: updateAt || new Date(),
            statusUpdate: (type === BulkUpdateFlyoverType.Status && (data as BulkStatusEditInfo)) || undefined,
            priceUpdate: (type === BulkUpdateFlyoverType.Price && (data as PriceDeltaUpdateEntity)) || undefined,
            patchUpdate: (patchUpdateTypes.includes(type) && (data as PatchDeltaUpdate)) || undefined,
            totalItems: processedItemsCount,
            totalProcessedRows: processedItemsCount,
        };

        try {
            await this.invoiceItemService.createBulkUpdate(bulkUpdatesBody);
            const practicesCount = (itemsSearchQuery?.siteId && castArray(itemsSearchQuery.siteId)?.length) || undefined;

            Notifications.success(this.getBulkUpdateSuccessMessage(processedGroupedItemsCount, practicesCount, isScheduledUpdate));
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    };

    getBulkUpdateSuccessMessage = (count: number = 0, practiceCount: number = 0, isScheduledUpdate: boolean): NotificationContent => {
        let successMessage, subHead;
        if (isScheduledUpdate) {
            const message1 = i18n.t('controlCenter:toastMessages:scheduledBulkUpdateMessage1', {
                count,
                defaultValue: `Update of ${count} Item(s) at `,
            });
            const message2 = i18n.t('controlCenter:toastMessages:scheduledBulkUpdateMessage2', {
                count: practiceCount,
                defaultValue: `${practiceCount} Practice Successfully Scheduled`,
            });

            successMessage = message1 + message2;
            subHead = i18n.t('controlCenter:toastMessages:scheduledUpdateSubHead', { defaultValue: `View the update's status in the activity log.` });
        } else {
            const message1 = i18n.t('controlCenter:toastMessages:bulkUpdateMessage1', {
                count,
                defaultValue: `Updating ${count} Item(s) at `,
            });
            const message2 = i18n.t('controlCenter:toastMessages:bulkUpdateMessage2', {
                count: practiceCount,
                defaultValue: `${practiceCount} Practice(s)`,
            });

            successMessage = message1 + message2;
            subHead = i18n.t('controlCenter:toastMessages:updateSubHead', { defaultValue: `Refresh page to see changes as they complete.` });
        }
        return ToastWithSubHeader({ message: successMessage, subMessage: subHead });
    };

    fetchBulkUpdateStats = async (query: GetItemQuery): Promise<void> => {
        this._isLoadingItemsStats = true;

        clearTimeout(this._timeout as number);
        const timeout = setTimeout(async () => {
            try {
                const { hits } = await this.invoiceItemService.getItems({
                    ...query,
                    onlyStats: true,
                });

                if (timeout !== this._timeout) {
                    return;
                }

                this._selectionModel = {
                    ...this.selectionModel,
                    total: hits?.total,
                    groups: hits?.groups,
                };

                this._isLoadingItemsStats = false;
            } catch (e) {
                console.error(e);
                this._isLoadingItemsStats = false;
            }
        }, 1000);

        this._timeout = timeout;
    };
}
