import { FormType, ValidationResult } from '@enterprise/common';
import { BulkUpdateFlyoverType } from '../ItemsPageStore';
import { PatchDeltaUpdate } from '../../../../core/models/datasource/invoiceItems/parts/patchDeltaUpdate';
import i18n from 'i18next';

export interface PatchUpdateModel extends PatchDeltaUpdate {
    name?: string;
    updateAt?: Date;
}

export class BulkPatchEditFormType extends FormType<PatchUpdateModel> {
    constructor(private type: BulkUpdateFlyoverType) {
        super();
    }
    getEntity(values: PatchUpdateModel): PatchUpdateModel {
        const base = {
            name: values.name,
            updateAt: values.updateAt,
        };

        if (this.type === BulkUpdateFlyoverType.SupplierName) {
            return {
                animanaSpecificFields: {
                    supplierName: values.animanaSpecificFields?.supplierName || null,
                },
                ...base,
            };
        }

        if (this.type === BulkUpdateFlyoverType.SupplierCode) {
            return {
                animanaSpecificFields: {
                    supplierCode: values.animanaSpecificFields?.supplierCode || null,
                },
                ...base,
            };
        }

        if (
            this.type === BulkUpdateFlyoverType.AnalysisGroup1 ||
            this.type === BulkUpdateFlyoverType.AnalysisGroup2 ||
            this.type === BulkUpdateFlyoverType.AnalysisGroup3
        ) {
            return {
                animanaSpecificFields: {
                    productAnalysisGroup1Name: values.animanaSpecificFields?.productAnalysisGroup1Name || null,
                    productAnalysisGroup2Name: values.animanaSpecificFields?.productAnalysisGroup2Name || null,
                    productAnalysisGroup3Name: values.animanaSpecificFields?.productAnalysisGroup3Name || null,
                },
                ...base,
            };
        }

        if (this.type === BulkUpdateFlyoverType.Description) {
            return {
                hospitalDescription: values.hospitalDescription,
                ...base,
            };
        }

        if (this.type === BulkUpdateFlyoverType.Classification) {
            return {
                classification: values.classification,
                subClassification: values.subClassification,
                ...base,
            };
        }

        return base;
    }

    validate(values: PatchUpdateModel): Promise<ValidationResult> {
        const errors: ValidationResult = {};

        if (this.type === BulkUpdateFlyoverType.Description) {
            if (!values.hospitalDescription) {
                errors.hospitalDescription = i18n.t('validation:isNotEmpty', {
                    field: i18n.t('controlCenter:itemFields.practiceDescription', 'Practice Description'),
                });
            }

            if (values.hospitalDescription && !new RegExp('^[a-zA-Z0-9].*').test(values.hospitalDescription)) {
                errors.hospitalDescription = i18n.t('validation:isNotEmpty', {
                    field: i18n.t(
                        'controlCenter:validation.hospitalDescription_first_char',
                        'The first character of the hospital description must be one of the following characters. 0-9 or A-Z',
                    ),
                });
            }
        }

        if (this.type === BulkUpdateFlyoverType.Classification) {
            if (!values.classification?.pimsGeneratedId) {
                errors.classification = {
                    pimsGeneratedId: i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.classId', 'Class ID') }),
                };
            }
        }

        if (
            this.type === BulkUpdateFlyoverType.AnalysisGroup1 ||
            this.type === BulkUpdateFlyoverType.AnalysisGroup2 ||
            this.type === BulkUpdateFlyoverType.AnalysisGroup3
        ) {
            if (
                !values.animanaSpecificFields?.productAnalysisGroup1Name &&
                !values.animanaSpecificFields?.productAnalysisGroup2Name &&
                !values.animanaSpecificFields?.productAnalysisGroup3Name
            ) {
                errors.animanaSpecificFields = {
                    productAnalysisGroups: i18n.t('validation:isNotEmpty', {
                        field: i18n.t('controlCenter:itemFields.productAnalysisGroups', 'Analysis Groups'),
                    }),
                };
            }
        }

        return Promise.resolve(errors);
    }
}
