import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Field, useFormState } from '@enterprise/common';
import { SpotTextInput } from '@enterprise/spot';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { useEffect, useRef } from 'react';
import { PatchUpdateModel } from '../../formType/bulkPatchEditFormType';

export const SupplierCodeEditFormFieldset = observer(function SupplierCodeEditFormFieldset() {
    const { values } = useFormState<PatchUpdateModel>();
    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current?.focus();
        }
    }, [ref]);

    const supplierCodePlaceholder = i18n.t('controlCenter:itemFields.supplierCode', 'Supplier Code');

    return (
        <Container fluid={true}>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }} className="spot-form__field-group">
                        <Field
                            name="animanaSpecificFields.supplierCode"
                            format={(value) => {
                                if (values?.animanaSpecificFields) {
                                    values.animanaSpecificFields.supplierCode = value ? value : null;
                                }
                                return value;
                            }}
                        >
                            {({ input, meta }) => (
                                <div>
                                    <label className="spot-form__label">{i18n.t('controlCenter:itemFields.supplierCode', 'Supplier Code')}</label>
                                    <SpotTextInput ref={ref as any} {...input} error={meta} placeholder={supplierCodePlaceholder} />
                                </div>
                            )}
                        </Field>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
