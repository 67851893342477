import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Field } from '@enterprise/common';
import { SpotSelectBox, SpotSelectOption, SpotSvg } from '@enterprise/spot';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { useStores } from '../../../../../hooks';
import { AnalysisGroupType } from '../../../../../core/models/datasource/lookups';
import classNames from 'classnames';

export const AnalysisGroupEditFormFieldset = observer(function AnalysisGroupEditFormFieldset() {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current?.focus();
        }
    }, [ref]);

    return (
        <Container fluid={true}>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }} className="spot-form__field-group">
                        <Field
                            name="animanaSpecificFields.productAnalysisGroup1Name"
                            parse={(value) => (value === undefined || value === '' ? null : value)}
                            format={(value?: string | null) => {
                                const name = itemsPageStore
                                    .getLookupResults(itemsPageStore.analysisGroups)
                                    .find((item) => item.name.toUpperCase() === value?.toUpperCase())?.name;
                                return name;
                            }}
                        >
                            {({ input, meta }) => (
                                <div>
                                    <label className="spot-form__label">
                                        {i18n.t('controlCenter:itemFields.analysisGroup1', 'Analysis Group 1')}
                                    </label>
                                    <SpotSelectBox {...input} error={meta}>
                                        {itemsPageStore
                                            .getLookupResults(itemsPageStore.analysisGroups, undefined, { type: AnalysisGroupType.Group1 })
                                            .map(({ id, name }) => (
                                                <SpotSelectOption key={id} value={name}>
                                                    {name}
                                                </SpotSelectOption>
                                            ))}
                                    </SpotSelectBox>
                                </div>
                            )}
                        </Field>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }} className="spot-form__field-group">
                        <Field
                            name="animanaSpecificFields.productAnalysisGroup2Name"
                            parse={(value) => (value === undefined || value === '' ? null : value)}
                            format={(value?: string | null) => {
                                const name = itemsPageStore
                                    .getLookupResults(itemsPageStore.analysisGroups)
                                    .find((item) => item.name.toUpperCase() === value?.toUpperCase())?.name;
                                return name;
                            }}
                        >
                            {({ input, meta }) => (
                                <div>
                                    <label className="spot-form__label">
                                        {i18n.t('controlCenter:itemFields.analysisGroup2', 'Analysis Group 2')}
                                    </label>
                                    <SpotSelectBox {...input} error={meta}>
                                        {itemsPageStore
                                            .getLookupResults(itemsPageStore.analysisGroups, undefined, { type: AnalysisGroupType.Group2 })
                                            .map(({ id, name }) => (
                                                <SpotSelectOption key={id} value={name}>
                                                    {name}
                                                </SpotSelectOption>
                                            ))}
                                    </SpotSelectBox>
                                </div>
                            )}
                        </Field>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }} className="spot-form__field-group">
                        <Field
                            name="animanaSpecificFields.productAnalysisGroup3Name"
                            parse={(value) => (value === undefined || value === '' ? null : value)}
                            format={(value?: string | null) => {
                                const name = itemsPageStore
                                    .getLookupResults(itemsPageStore.analysisGroups)
                                    .find((item) => item.name.toUpperCase() === value?.toUpperCase())?.name;
                                return name;
                            }}
                        >
                            {({ input, meta }) => (
                                <div>
                                    <label className="spot-form__label">
                                        {i18n.t('controlCenter:itemFields.analysisGroup3', 'Analysis Group 3')}
                                    </label>
                                    <SpotSelectBox {...input} error={meta}>
                                        {itemsPageStore
                                            .getLookupResults(itemsPageStore.analysisGroups, undefined, { type: AnalysisGroupType.Group3 })
                                            .map(({ id, name }) => (
                                                <SpotSelectOption key={id} value={name}>
                                                    {name}
                                                </SpotSelectOption>
                                            ))}
                                    </SpotSelectBox>
                                </div>
                            )}
                        </Field>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ marginTop: 32, boxShadow: 'none' }} className="spot-message priority-standard--warning">
                        <div className="message--content">
                            <div className="message--body">
                                The bulk update feature for Analysis Groups allows for Create and Update actions, but does not support the Clear
                                operation.
                            </div>
                        </div>
                        <div className={classNames('icon-tab')}>
                            <SpotSvg icon={'alert-notification'} className="spot-icon" />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
