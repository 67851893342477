import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { first, uniq } from 'lodash';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { SpotLoading, SpotPill, SpotTextInput } from '@enterprise/spot';
import { observer } from 'mobx-react';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { BulkUpdateFlyoverType } from '../../ItemsPageStore';
import { MARKUP_TYPE_UP, PRICE_TYPE_BASE } from '../constants';
import { InvoiceItem, InvoiceItemWithVariantDetail } from '../../../../../core/models';
import i18n from 'i18next';
import moment from 'moment';
import { SelectionGridState } from '../ItemAddPracticeBulkUpdate';
import { DeltaUpdateModel } from '../../formType/BulkPriceEditFormType';
import { BulkStatusEditModel } from '../../formType/BulkStatusEditFormType';
import { PatchUpdateModel } from '../../formType/bulkPatchEditFormType';
import { SelectionModel } from '../../../../../store/invoiceItem.store';
import { Dictionary } from 'async';
import { useStores } from '../../../../../hooks';
import { AnalysisGroupType } from '../../../../../core/models/datasource/lookups';

interface UpdateSummaryProps {
    type: string;
    data: DeltaUpdateModel | BulkStatusEditModel | PatchUpdateModel;
    setName: any;
    selectionGridState: SelectionGridState;
    selectedItems: InvoiceItemWithVariantDetail[];
    selectionModel?: SelectionModel;
    processedCount: number;
    groupedItems: Dictionary<InvoiceItem[]>;
}

interface UpdateSummaryState {
    name: string;
}

export const UpdateSummary = observer(function UpdateSummary(props: UpdateSummaryProps) {
    const {
        domain: { itemsPageStore, invoiceItemStore },
    } = useStores();

    const { type, selectionModel, processedCount, selectedItems, selectionGridState, groupedItems } = props;
    const [state, setState] = useState<UpdateSummaryState>();
    const sites = useMemo(() => {
        const items = Array.from(selectedItems);
        const sites = selectionGridState?.selectedPractices;
        const result: any[] = [];

        items.forEach((item) => {
            item.providers.map((provider) => {
                sites?.map((site) => {
                    if (provider.siteId === site.id) {
                        result.push(site.name);
                    }
                });
            });
        });
        return uniq(result);
    }, [selectedItems, selectionGridState?.selectedPractices]);

    useEffect(() => {
        if (selectionGridState.updateName) {
            props.setName(selectionGridState.updateName);
            setState({ name: selectionGridState.updateName });
        } else {
            props.setName(buildName());
            setState({ name: buildName() });
            selectionGridState.updateName = buildName();
        }
    }, []);

    const renderItemsList = () => {
        return (
            <ListGroup variant="flush" className="item-list">
                {Object.values(groupedItems).map((items) => {
                    const item = first(items);
                    return (
                        <ListGroup.Item key={item?.pimsGeneratedId}>
                            <div style={{ display: 'inline-block' }}>
                                {item?.hospitalDescription}&nbsp;
                                <small>{item?.pimsId || item?.pimsGeneratedId}</small>
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        );
    };

    const renderSitesList = () => {
        return (
            <ListGroup variant="flush">
                {sites.map((i) => (
                    <ListGroup.Item key={i}>
                        <div style={{ display: 'inline-block' }}>{i}</div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    };

    const renderSupplierNameSummary = () => {
        const data = props.data as PatchUpdateModel;
        const title = i18n.t('controlCenter:import.setSupplier', 'Set Supplier');
        const remove = i18n.t('controlCenter:import.clearSupplier', 'Clear Supplier');

        return <h6>{data.animanaSpecificFields?.supplierName ? `${title} "${data.animanaSpecificFields?.supplierName}"` : remove}</h6>;
    };

    const renderSupplierCodeSummary = () => {
        const data = props.data as PatchUpdateModel;
        const title = i18n.t('controlCenter:import.setPracticeDescription', 'Set Supplier Code');
        const remove = i18n.t('controlCenter:import.clearPracticeDescription', 'Clear Supplier Code');

        return <h6>{data.animanaSpecificFields?.supplierCode ? `${title} "${data.animanaSpecificFields?.supplierCode}"` : remove}</h6>;
    };

    const getGroupName = (type: AnalysisGroupType) => {
        const data = props.data as PatchUpdateModel;
        return [
            data.animanaSpecificFields?.productAnalysisGroup1Name,
            data.animanaSpecificFields?.productAnalysisGroup2Name,
            data.animanaSpecificFields?.productAnalysisGroup3Name,
        ].reduce((agg, item) => {
            return item && agg ? `${agg}, ${item}` : agg || item;
        });
    };

    const renderAnalysisGroup = (type: AnalysisGroupType): string => {
        const groupName = getGroupName(type);
        const title = i18n.t(`controlCenter:import.setAnalysisGroups`, `Set Analysis Groups`);

        return `${title} "${groupName}"`;
    };

    const renderDescriptionSummary = () => {
        const data = props.data as PatchUpdateModel;
        const title = i18n.t('controlCenter.import.setPracticeDescription', 'Set Practice Description');

        return <h6>{`${title} "${data.hospitalDescription}"`}</h6>;
    };

    const renderClassificationSummary = () => {
        const data = props.data as PatchUpdateModel;
        const { isAnimanaOnly } = itemsPageStore;
        const title = isAnimanaOnly
            ? i18n.t('controlCenter:import.setProductGroup', 'Set Product Group')
            : i18n.t('controlCenter.import.setClassification', 'Set Classification');

        return (
            <h6>
                {`${title} "${data.classification?.name}"`}
                {data.subClassification?.name &&
                    ` ${i18n.t('controlCenter.import.andSubClassification', 'and SubClassification')} "${data.subClassification.name}"`}
            </h6>
        );
    };

    const renderStatusSummary = () => {
        const data = props.data as BulkStatusEditModel;
        return (
            <h6>
                {data.isActive === '1'
                    ? i18n.t('controlCenter.import.setStatusActive', 'Set Status Active')
                    : i18n.t('controlCenter.import.setStatusInactive', 'Set Status Inactive')}
            </h6>
        );
    };

    const renderPriceSummary = () => {
        const data = props.data as DeltaUpdateModel;
        return (
            <>
                {data.priceType === PRICE_TYPE_BASE
                    ? `${i18n.t('controlCenter.import.basePrice', 'Base Price')} `
                    : `${i18n.t('controlCenter.import.minimumPrice', 'Minimum Price')} `}
                {data.percent ? `${data.percent}% ` : ''}
                {data.minimumAmount ? `$${data.minimumAmount} ` : ''}
                {data.markupType === MARKUP_TYPE_UP
                    ? `${i18n.t('controlCenter.import.markUp', 'MarkUp')}`
                    : `${i18n.t('controlCenter.import.markDown', 'MarkDown')}`}
                {data.isIncludeZero ? ` ${i18n.t('controlCenter.import.includesZero', 'Includes Zero')}` : ''}
            </>
        );
    };

    const buildName = () => {
        const date = moment().format('ll');
        let outText = '';

        if (type === BulkUpdateFlyoverType.SupplierName) {
            const data = props.data as PatchUpdateModel;
            const title = i18n.t('controlCenter:import.setSupplierName', 'Set Supplier');
            const remove = i18n.t('controlCenter:import.clearSupplierName', 'Clear Supplier');

            outText = data.animanaSpecificFields?.supplierName ? `${title} "${data.animanaSpecificFields?.supplierName}"` : remove;
        }

        if (type === BulkUpdateFlyoverType.SupplierCode) {
            const data = props.data as PatchUpdateModel;
            const title = i18n.t('controlCenter:import.setSupplierCode', 'Set Supplier Code');
            const remove = i18n.t('controlCenter:import.clearSupplierCode', 'Clear Supplier Code');

            outText = data.animanaSpecificFields?.supplierCode ? `${title} "${data.animanaSpecificFields?.supplierCode}"` : remove;
        }

        if (type === BulkUpdateFlyoverType.AnalysisGroup1) {
            outText = renderAnalysisGroup(AnalysisGroupType.Group1);
        }

        if (type === BulkUpdateFlyoverType.AnalysisGroup2) {
            outText = renderAnalysisGroup(AnalysisGroupType.Group2);
        }

        if (type === BulkUpdateFlyoverType.AnalysisGroup3) {
            outText = renderAnalysisGroup(AnalysisGroupType.Group3);
        }

        if (type === BulkUpdateFlyoverType.Description) {
            const data = props.data as PatchUpdateModel;
            const title = i18n.t('controlCenter:import.setPracticeDescription', 'Set Practice Description');

            outText = `${title} "${data.hospitalDescription}"`;
        }

        if (type === BulkUpdateFlyoverType.Classification) {
            const data = props.data as PatchUpdateModel;
            const { isAnimanaOnly } = itemsPageStore;
            const title = isAnimanaOnly
                ? i18n.t('controlCenter:import.setProductGroup', 'Set Product Group')
                : i18n.t('controlCenter.import.setClassification', 'Set Classification');

            outText = `${title} "${data.classification?.name}"`;
            if (data.subClassification?.name) {
                outText += ` ${i18n.t('controlCenter.import.andSubClassification', 'and SubClassification')} "${data.subClassification.name}"`;
            }
        }

        if (type === BulkUpdateFlyoverType.Status) {
            const data = props.data as BulkStatusEditModel;
            outText =
                data.isActive === '1'
                    ? i18n.t('controlCenter.import.setStatusActive', 'Set Status Active')
                    : i18n.t('controlCenter.import.setStatusInactive', 'Set Status Inactive');
        }

        if (type === BulkUpdateFlyoverType.Price) {
            const data = props.data as DeltaUpdateModel;
            outText =
                (data.priceType === PRICE_TYPE_BASE
                    ? i18n.t('controlCenter.import.basePrice', 'Base Price')
                    : i18n.t('controlCenter.import.minimumPrice', 'Minimum Price')) + ' ';
            outText = outText + (data.percent ? `${data.percent}%` : data.minimumAmount ? `$${data.minimumAmount}` : '');
            outText = `${outText} ${
                data.markupType === MARKUP_TYPE_UP
                    ? i18n.t('controlCenter.import.markUp', 'MarkUp')
                    : i18n.t('controlCenter.import.markDown', 'MarkDown')
            }`;
        }

        return `${date} ${outText}`;
    };

    return (
        <div className="update-summary-container">
            <Container fluid={true}>
                <Row>
                    <div className={'summaryTitle'}>
                        <div className={'summaryText'} data-automation-id={'bulk-update-summary-text'}>
                            {type === BulkUpdateFlyoverType.SupplierName && renderSupplierNameSummary()}
                            {type === BulkUpdateFlyoverType.SupplierCode && renderSupplierCodeSummary()}
                            {type === BulkUpdateFlyoverType.AnalysisGroup1 && <h6>{renderAnalysisGroup(AnalysisGroupType.Group1)}</h6>}
                            {type === BulkUpdateFlyoverType.AnalysisGroup2 && renderAnalysisGroup(AnalysisGroupType.Group2)}
                            {type === BulkUpdateFlyoverType.AnalysisGroup3 && renderAnalysisGroup(AnalysisGroupType.Group3)}
                            {type === BulkUpdateFlyoverType.Description && renderDescriptionSummary()}
                            {type === BulkUpdateFlyoverType.Classification && renderClassificationSummary()}
                            {type === BulkUpdateFlyoverType.Status && renderStatusSummary()}
                            {type === BulkUpdateFlyoverType.Price && renderPriceSummary()}
                        </div>
                    </div>
                </Row>
                <Row className="separator">
                    {i18n.t('controlCenter.import.updateName', 'Update Name')}
                    <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                        <SpotTextInput
                            data-automation-id={'bulk-update-name-input-text'}
                            value={state?.name}
                            id={'updateNameInput'}
                            onChange={(e) => {
                                setState({ name: e.target.value });
                                props.setName(e.target.value);
                                selectionGridState.updateName = e.target.value;
                            }}
                        />
                    </div>
                </Row>
                <Row className="separator">
                    <Col xs={12}>
                        <CollapsiblePanel expandedContent={!selectionModel?.all && renderItemsList()} hideToggleIcon={Boolean(selectionModel?.all)}>
                            <>
                                <div data-automation-id={'bulk-update-items-count'}>
                                    {invoiceItemStore.isLoadingItemsStats && <SpotLoading />}
                                    {!invoiceItemStore.isLoadingItemsStats && (
                                        <SpotPill color="#33bfcc" isSmall={true}>
                                            {processedCount}
                                        </SpotPill>
                                    )}{' '}
                                    {i18n.t('controlCenter.import.items', 'Items')}
                                </div>
                            </>
                        </CollapsiblePanel>
                    </Col>
                </Row>
                <Row className="separator">
                    <Col xs={12}>
                        <CollapsiblePanel expandedContent={renderSitesList()}>
                            <>
                                <div data-automation-id={'bulk-update-practices-count'}>
                                    <SpotPill color="#33bfcc" isSmall={true}>
                                        {sites.length}
                                    </SpotPill>{' '}
                                    {i18n.t('controlCenter.import.Practices', 'Practices')}
                                </div>
                            </>
                        </CollapsiblePanel>
                    </Col>
                </Row>
            </Container>
        </div>
    );
});
