import { GridApi, GridColDef, GridValueGetterParams } from '@enterprise/material-data-grid';
import { InvoiceItem } from '../../../../../core/models';
import { MutableRefObject, useMemo } from 'react';
import i18n from '../../../../../i18n';
import { useStores } from '../../../../../hooks';
import { cell, getGroupItems, header, ItemsGridColumn, ItemsType } from './useItemsGridColumns.hook';
import { AnalysisGroupType } from '../../../../../core/models/datasource/lookups';
import { first } from 'lodash';

export enum AnimanaSpecificColumn {
    SupplierName = 'supplierName',
    SupplierCode = 'supplierCode',
    AnalysisGroup1 = 'analysisGroup1',
    AnalysisGroup2 = 'analysisGroup2',
    AnalysisGroup3 = 'analysisGroup3',
}

interface UseAnimanaSpecificColumnsParams {
    apiRef: MutableRefObject<GridApi>;
    itemsType?: ItemsType;
    columnNames: Map<ItemsGridColumn, string>;
    columns: GridColDef<InvoiceItem>[];
}

export interface AnimanaSpecificColumnsDetails {
    animanaColumnNames: Map<AnimanaSpecificColumn | ItemsGridColumn, string>;
    animanaColumns: GridColDef<InvoiceItem>[];
}

export const useAnimanaSpecificColumns = (params: UseAnimanaSpecificColumnsParams): AnimanaSpecificColumnsDetails => {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const { apiRef, itemsType, columnNames, columns } = params;

    const animanaColumnNames = useMemo(
        () =>
            new Map<AnimanaSpecificColumn | ItemsGridColumn, string>(columnNames)
                .set(AnimanaSpecificColumn.SupplierName, i18n.t('controlCenter:supplier', 'Supplier'))
                .set(AnimanaSpecificColumn.SupplierCode, i18n.t('controlCenter:supplierCode', 'Supplier Code'))
                .set(AnimanaSpecificColumn.AnalysisGroup1, i18n.t('controlCenter:analysisGroup1', 'Analysis Group 1'))
                .set(AnimanaSpecificColumn.AnalysisGroup2, i18n.t('controlCenter:analysisGroup2', 'Analysis Group 2'))
                .set(AnimanaSpecificColumn.AnalysisGroup3, i18n.t('controlCenter:analysisGroup3', 'Analysis Group 3')),
        [columnNames],
    );

    const suppliersMap = useMemo(() => {
        return itemsPageStore.suppliers.reduce(
            (agg, item) =>
                agg.set(
                    item.provider.siteId,
                    item.results.reduce((items, { name }) => ({ ...items, [String(name)?.toUpperCase()]: name }), {} as Record<string, string>),
                ),
            new Map<string, Record<string, string>>(),
        );
    }, [itemsPageStore.suppliers]);

    const column = ({ field, headerName, ...props }: GridColDef<InvoiceItem> & { field: AnimanaSpecificColumn }) => {
        return {
            field: field,
            headerName: headerName || animanaColumnNames.get(field),
            width: 200,
            disableColumnMenu: false,
            groupable: false,
            renderHeader: header({ label: animanaColumnNames.get(field) }),
            renderCell: cell(),
            ...props,
        };
    };

    const valueGetter = (value: (item: InvoiceItem) => unknown) => {
        return ({ row, rowNode }: GridValueGetterParams<string, InvoiceItem>) => {
            if (!rowNode.isAutoGenerated) {
                return value(row);
            }

            const items = getGroupItems(apiRef, rowNode.id);
            if (!items?.length) {
                return '-';
            }

            const first = value(items[0]);
            const containsOne = !items.some((val) => value(val) !== first);

            if (containsOne) {
                return first;
            } else {
                return '--';
            }
        };
    };

    const animanaColumns = useMemo<GridColDef<InvoiceItem>[]>(() => {
        if (itemsType !== ItemsType.Animana) {
            return [...columns];
        }

        return [
            ...columns,
            column({
                field: AnimanaSpecificColumn.SupplierName,
                valueGetter: valueGetter((item: InvoiceItem) => {
                    const name = item.animanaSpecificFields?.supplierName?.toUpperCase();
                    return name && suppliersMap.get(item.provider.siteId)?.[name || ''];
                }),
            }),
            column({
                field: AnimanaSpecificColumn.SupplierCode,
                valueGetter: valueGetter((item: InvoiceItem) => {
                    return item.animanaSpecificFields?.supplierCode;
                }),
            }),
            column({
                field: AnimanaSpecificColumn.AnalysisGroup1,
                valueGetter: valueGetter((item: InvoiceItem) => {
                    const query = { type: AnalysisGroupType.Group1, id: item.animanaSpecificFields?.productAnalysisGroup1Uuid };
                    return first(itemsPageStore.getLookupResults(itemsPageStore.analysisGroups, item.provider?.siteId, query))?.name;
                }),
            }),
            column({
                field: AnimanaSpecificColumn.AnalysisGroup2,
                valueGetter: valueGetter((item: InvoiceItem) => {
                    const query = { type: AnalysisGroupType.Group2, id: item.animanaSpecificFields?.productAnalysisGroup2Uuid };
                    return first(itemsPageStore.getLookupResults(itemsPageStore.analysisGroups, item.provider?.siteId, query))?.name;
                }),
            }),
            column({
                field: AnimanaSpecificColumn.AnalysisGroup3,
                valueGetter: valueGetter((item: InvoiceItem) => {
                    const query = { type: AnalysisGroupType.Group3, id: item.animanaSpecificFields?.productAnalysisGroup3Uuid };
                    return first(itemsPageStore.getLookupResults(itemsPageStore.analysisGroups, item.provider?.siteId, query))?.name;
                }),
            }),
        ];
    }, [columns]);

    return {
        animanaColumnNames,
        animanaColumns,
    };
};
