import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Field } from '@enterprise/common';
import { SpotFieldError, SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { useEffect, useRef } from 'react';
import { useStores } from '../../../../../hooks';
import { LookupSupplier } from '../../../../../core/models/datasource/lookups';

export const SupplierNameEditFormFieldset = observer(function SupplierNameEditFormFieldset() {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const suppliers: LookupSupplier[] = itemsPageStore.getLookupResults(itemsPageStore.suppliers);
    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current?.focus();
        }
    }, [ref]);

    return (
        <Container fluid={true}>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }} className="spot-form__field-group">
                        <Field
                            name="animanaSpecificFields.supplierName"
                            parse={(value) => (value === undefined || value === '' ? null : value)}
                            format={(value?: string | null) => {
                                const supplier = suppliers.find(({ name }) => name.toUpperCase() === value?.toUpperCase());
                                return supplier?.name;
                            }}
                        >
                            {({ input, meta }) => (
                                <div>
                                    <label className="spot-form__label">{i18n.t('controlCenter:itemFields.supplier', 'Supplier')}</label>
                                    <SpotSelectBox ref={ref as any} {...input} error={meta}>
                                        {suppliers.map((unit) => (
                                            <SpotSelectOption key={unit.id} value={unit.name}>
                                                {unit.name}
                                            </SpotSelectOption>
                                        ))}
                                    </SpotSelectBox>
                                    <SpotFieldError meta={meta} />
                                </div>
                            )}
                        </Field>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
