import React, { ReactElement, useMemo } from 'react';
import { GridColumnMenu, GridColumnMenuProps } from '@enterprise/material-data-grid';
import styles from './customColumnMenuComponent.module.scss';
import i18n from '../../../../../i18n';
import { SelectionModel } from '../../../../../store/invoiceItem.store';
import { AnimanaSpecificColumn, ItemsGridColumn } from '../hooks';
import classNames from 'classnames';
import { BulkUpdateFlyoverType } from '../../ItemsPageStore';
import { Divider } from '@mui/material';

export interface ItemResultsProps {
    selection?: SelectionModel;
    onBulkActionChange?: (props: { type: BulkUpdateFlyoverType }) => void;
}

export const customColumnMenu = ({ selection, onBulkActionChange }: ItemResultsProps) =>
    function CustomColumnMenuComponent(props: GridColumnMenuProps): ReactElement {
        const { hideMenu, currentColumn, color, ...other } = props;
        const field = currentColumn.field as ItemsGridColumn | AnimanaSpecificColumn;
        const customMenuColumn = useMemo<{ type: BulkUpdateFlyoverType; name: string } | undefined>(() => {
            return (
                (field.includes(ItemsGridColumn.Description) && {
                    type: BulkUpdateFlyoverType.Description,
                    name: i18n.t('controlCenter:columnHeaderMenus:bulkUpdateDescription', 'Bulk Update Description'),
                }) ||
                (field === ItemsGridColumn.Classification && {
                    type: BulkUpdateFlyoverType.Classification,
                    name: i18n.t('controlCenter:columnHeaderMenus:bulkUpdateClassification', 'Bulk Update Classification'),
                }) ||
                (field === ItemsGridColumn.Status && {
                    type: BulkUpdateFlyoverType.Status,
                    name: i18n.t('controlCenter:columnHeaderMenus:bulkUpdateStatus', 'Bulk Update Status'),
                }) ||
                (field === ItemsGridColumn.SellingPrice && {
                    type: BulkUpdateFlyoverType.Price,
                    name: i18n.t('controlCenter:columnHeaderMenus:bulkUpdatePrice', 'Bulk Update Price'),
                }) ||
                (field === AnimanaSpecificColumn.SupplierName && {
                    type: BulkUpdateFlyoverType.SupplierName,
                    name: i18n.t('controlCenter:columnHeaderMenus:bulkUpdateSupplier', 'Bulk Update Supplier'),
                }) ||
                (field === AnimanaSpecificColumn.SupplierCode && {
                    type: BulkUpdateFlyoverType.SupplierCode,
                    name: i18n.t('controlCenter:columnHeaderMenus:bulkUpdateSupplierCode', 'Bulk Update Supplier Code'),
                }) ||
                (field === AnimanaSpecificColumn.AnalysisGroup1 && {
                    type: BulkUpdateFlyoverType.AnalysisGroup1,
                    name: i18n.t('controlCenter:columnHeaderMenus:analysisGroups', 'Bulk Update Analysis Groups'),
                }) ||
                (field === AnimanaSpecificColumn.AnalysisGroup2 && {
                    type: BulkUpdateFlyoverType.AnalysisGroup2,
                    name: i18n.t('controlCenter:columnHeaderMenus:analysisGroups', 'Bulk Update Analysis Groups'),
                }) ||
                (field === AnimanaSpecificColumn.AnalysisGroup3 && {
                    type: BulkUpdateFlyoverType.AnalysisGroup3,
                    name: i18n.t('controlCenter:columnHeaderMenus:analysisGroups', 'Bulk Update Analysis Groups'),
                }) ||
                undefined
            );
        }, [currentColumn]);

        return (
            <>
                <GridColumnMenu
                    data-automation-id={`${field}-header-menu`}
                    hideMenu={hideMenu}
                    currentColumn={currentColumn}
                    // todo: check if selection required to apply css class
                    className={classNames({ [styles.gridColumnMenu]: Boolean(customMenuColumn && selection) })}
                    {...other}
                />
                <Divider />
                {customMenuColumn && selection && (
                    <div className={styles.headerMenuCustomItemContainer} data-automation-id={`${field}-update-menu-item`}>
                        <div
                            className={classNames(styles.headerMenuCustomItem, {
                                [styles.disabled]: !selection.items?.size && !selection.excluded?.size && !selection.all,
                            })}
                            onClick={(event) => {
                                hideMenu(event);
                                onBulkActionChange?.({ type: customMenuColumn.type as BulkUpdateFlyoverType });
                            }}
                        >
                            {customMenuColumn.name}
                        </div>
                    </div>
                )}
            </>
        );
    };
