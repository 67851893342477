import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { SpotLoading } from '@enterprise/spot';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import { ItemEditPage } from '../ItemEditPage';
import { ItemSearchPanel } from './itemsSearchPanel';
import { GetItemQuery, InvoiceItem } from '../../../../core/models';
import { ItemsGrid, ItemsGridColumn, ItemsType } from '../itemsGrid';
import { ItemsGroupByType } from '../../../../core/models/organization/settings/enum';
import { useStores } from '../../../../hooks';
import { ItemEditQuery } from '../../../../store/invoiceItem.store';
import { BulkUpdateModal } from '../modals/BulkUpdateModal';
import styles from './invoiceItems.component.module.scss';
import i18n from 'i18next';
import { ItemAddPractices } from '../../components/ItemAddPractice/ItemAddPractices';
import { useItemBulkAction } from './useItemBulkAction.hook';
import { FEATURES } from '@enterprise/core';
import { BulkUpdateFlyoverType } from '../ItemsPageStore';
import { RootRoutes } from '../../../../router';
import { RouterPaths } from '../../../../router/RouterPaths';

export const InvoiceItems = observer(function InvoiceItemsComponent() {
    const {
        domain: { itemsPageStore, constantsStore, invoiceItemStore },
        ui: { app },
    } = useStores();

    const [searchQuery, setSearchQuery] = useState<GetItemQuery>();
    const scroll = '10m';
    const { addToPractices, bulkUpdate } = useItemBulkAction();
    const { itemsGroupBy, isLoadingItems: isLoadingItemsLegacy } = itemsPageStore;
    const { isLoadingItems, items = [], selectionModel, pagingModel, updateSelectionModel, columnsState } = invoiceItemStore;
    const { isLoading: isConstantsLoading, applications = [] } = constantsStore;
    const isLoading = (!items.length && isLoadingItems) || isLoadingItemsLegacy || isConstantsLoading;
    const enhancedUiPaginationEnabled = app.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI, true, { pagination: true });
    const grouping: ItemsGridColumn =
        (itemsGroupBy === ItemsGroupByType.Description && ItemsGridColumn.Description) ||
        (itemsGroupBy === ItemsGroupByType.Id && ItemsGridColumn.Id) ||
        ItemsGridColumn.Id;

    useEffect(() => {
        if (isLoading) {
            void itemsPageStore.load();
        }
    }, [isLoading]);

    const searchItems = async (query: GetItemQuery): Promise<void> => {
        const pageSize = applications.length > 10 ? 1000 : 500;
        const sizeQuery = enhancedUiPaginationEnabled ? { scroll, size: pageSize } : { size: 500 };
        await invoiceItemStore.fetchItems({ ...query, ...sizeQuery });
        setSearchQuery(query);
    };

    const onInvoiceItemsLoading = async (): Promise<void> => {
        if (enhancedUiPaginationEnabled) {
            await invoiceItemStore.fetchItems({ scroll, scrollId: pagingModel?.scrollId });
        }
    };

    const onEditHandler = (params: { query: ItemEditQuery }) => {
        const { query } = params;
        invoiceItemStore.openItemEditForm({ query });
    };

    const searchItemsForAutoComplete = async (query: GetItemQuery): Promise<InvoiceItem[]> => {
        return await invoiceItemStore.fetchItemsForAutoComplete(query);
    };

    const onBulkActionChange = (props: { type: BulkUpdateFlyoverType }): void => {
        const { type } = props;

        if (type === BulkUpdateFlyoverType.AddToPractices) {
            addToPractices.action();
        }

        if (
            type === BulkUpdateFlyoverType.Description ||
            type === BulkUpdateFlyoverType.Classification ||
            type === BulkUpdateFlyoverType.Status ||
            type === BulkUpdateFlyoverType.Price ||
            type === BulkUpdateFlyoverType.SupplierName ||
            type === BulkUpdateFlyoverType.SupplierCode ||
            type === BulkUpdateFlyoverType.AnalysisGroup1 ||
            type === BulkUpdateFlyoverType.AnalysisGroup2 ||
            type === BulkUpdateFlyoverType.AnalysisGroup3
        ) {
            bulkUpdate.action(type);
        }
    };

    return (
        <div className={styles.holder} data-automation-id="items-search-results">
            <RootRoutes>
                <Route path={`${RouterPaths.ControlCenterPages.Items}/:action`} element={<ItemEditPage />} />
            </RootRoutes>

            <Container className={styles.container} fluid={true}>
                <ItemSearchPanel searchItems={searchItems} searchItemsForAutoComplete={searchItemsForAutoComplete} />

                {isLoading && <SpotLoading />}
                {Boolean(items?.length) && (
                    <ItemsGrid
                        className={styles.grid}
                        type={(itemsPageStore.isAnimanaOnly && ItemsType.Animana) || undefined}
                        invoiceItems={items}
                        onInvoiceItemsLoading={onInvoiceItemsLoading}
                        loading={isLoadingItems}
                        grouping={[grouping]}
                        onEdit={onEditHandler}
                        selection={selectionModel}
                        pagination={pagingModel}
                        onSelectionChange={updateSelectionModel}
                        onBulkActionChange={onBulkActionChange}
                        columnsState={columnsState}
                        onColumnsStateChange={(state) => {
                            invoiceItemStore.updateColumnsState(state);
                        }}
                    />
                )}
            </Container>

            {addToPractices.itemsKeys && (
                <ItemAddPractices
                    sitesWithoutItems={addToPractices.practicesWithoutItems}
                    title={i18n.t('controlCenter:addItemToAdditionalPractices', 'Add this item to Additional Practices', {
                        count: addToPractices.itemsKeys.size,
                    })}
                    secondaryTitle={i18n.t('controlCenter:itemsSelected', `[${addToPractices.itemsKeys.size}] Item(s) Selected`, {
                        count: addToPractices.itemsKeys.size,
                    })}
                    onSubmit={async (params) => {
                        await addToPractices.submit(params);
                        updateSelectionModel(undefined);
                    }}
                    onClose={addToPractices.cancel}
                />
            )}

            {bulkUpdate.type && (
                <BulkUpdateModal
                    type={bulkUpdate.type}
                    selectedItems={bulkUpdate.selectedItems}
                    invoiceItems={bulkUpdate.invoiceItems}
                    excludedItems={Array.from(selectionModel?.excluded || new Map()).map(([, item]) => item)}
                    searchQuery={searchQuery}
                    selectionModel={selectionModel}
                    onClose={({ reason }) => {
                        bulkUpdate.cancel();
                        if (reason === 'action') {
                            updateSelectionModel(undefined);
                        }
                    }}
                />
            )}
        </div>
    );
});
