export enum TaskStatus {
    INITIALIZATION = 'INITIALIZATION',
    PENDING = 'PENDING',
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
    ERROR = 'ERROR',
    SKIPPED = 'SKIPPED',
    SUCCESS = 'SUCCESS',
    CANCEL = 'CANCEL',
}
